<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "Contact",
		extends: Base,
		data() {
			return {
				Name: "Contact",
				row:{},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods:{	 
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.project'() {
				this.search()
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List </h5>
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<select2 :options="mrProject" :object="['mp_id','mp_title']" v-model="filter.project">
											<option value="">-- Filter Project --</option>
										</select2>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Project</th>
									<th>{{fields.mc_full_name}}</th>
									<th>{{fields.mc_email}}</th>
									<th>{{fields.mc_create_date}}</th>
									<th>{{fields.mc_is_active}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.mc_project}}</td>
									<td>{{(v.mc_full_name)}}</td>
									<td>{{(v.mc_email)}}</td>
									<td>{{(v.mc_create_date||"").dates("format")}}</td>
									<td>
										<label v-if="v.mc_is_active=='Y'" class="label label-info">Read</label>
										<label v-else class="label label-danger">Unread</label>
									</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'">
											<i class="ti-marker-alt"></i>
										</router-link>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'">
											<i class="ti-trash"></i>
										</a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="mb-0 justify-content-end" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-6">
							<BoField name="mc_full_name" v-model="row.mc_full_name"></BoField>
							<BoField name="mc_email" v-model="row.mc_email"></BoField>
							<BoField name="mc_phone" v-model="row.mc_phone"></BoField>
							<BoField name="mc_subject" v-model="row.mc_subject"></BoField>
							<BoField name="mc_message" >
								<textarea name="content" class="form-control" v-model="row.mc_message"></textarea>
							</BoField>
							<BoField name="mc_is_active">
								<div class="row">
									<radio name="mc_is_active" v-model="row.mc_is_active" option="Y" :attr="validation('mc_is_active')">Read</radio>
									<radio name="mc_is_active" v-model="row.mc_is_active" option="N">Unread</radio>
								</div>
							</BoField>
						</div>

						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-rounded btn-loading">Submit</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>